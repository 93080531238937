@import '../../../../scss/theme-bootstrap';

.menu__item {
  .menu__image {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      max-width: 300px;
      margin-#{$rdirection}: 20px;
    }
  }
}
